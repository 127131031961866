autohideheader();
var logo_white;
var logo_colored;

function autohideheader() {
    jQuery(document).ready(function($) {



        var mainHeader = $('.cd-auto-hide-header'),
            secondaryNavigation = $('.cd-secondary-nav'),
            //this applies only if secondary nav is below intro section
            belowNavHeroContent = $('.sub-nav-hero'),
            headerHeight = mainHeader.height();

        //set scrolling variables
        var scrolling = false,
            previousTop = 0,
            currentTop = 0,
            scrollDelta = 10,
            scrollOffset = 30;


        $('body').css('paddingTop', $('.navigation').innerHeight());
        if ($('.wth_menu_theme_2').length) {
            $('body').addClass('wth_menu_theme_2').css('paddingTop', 0);
            mainHeader.addClass('menu_theme_2')
        }

        $('.hamburger').click(function() {
            $('.hamburger').toggleClass('is-active')
            $('.menu_accordion').toggleClass('is-active')
            $('body').toggleClass('menu-is-active')
            if ($('body').hasClass('menu-is-active')) {
                setTimeout(() => {
                    $('.menu-is-active .sideCanvas').css('z-index', '50')
                }, 500)
            } else {
                $('.sideCanvas').css('z-index', '-1')
            }
        })





        mainHeader.on('click', '.nav-trigger', function(event) {
            // open primary navigation on mobile
            event.preventDefault();
            mainHeader.toggleClass('nav-open');
        });

        if ((currentTop = previousTop)) { //this has been added by Elhamy
            mainHeader.removeClass('is-shown'); //this has been added by Elhamy
            console.log('its at top') //this has been added by Elhamy
        } //this has been added by Elhamy

        $(window).on('scroll', function() {
            if (!scrolling) {
                scrolling = true;
                (!window.requestAnimationFrame) ?
                setTimeout(autoHideHeader, 250): requestAnimationFrame(autoHideHeader);

            }
        });

        $(window).on('resize', function() {
            headerHeight = mainHeader.height();
        });

        function autoHideHeader() {
            var currentTop = $(window).scrollTop();

            (belowNavHeroContent.length > 0) ?
            checkStickyNavigation(currentTop) // secondary navigation below intro
                : checkSimpleNavigation(currentTop);

            previousTop = currentTop;
            scrolling = false;
        }
        console.log('previousTop1', previousTop)
        console.log('currentTop1', currentTop)

        function checkSimpleNavigation(currentTop) {
            //there's no secondary nav or secondary nav is below primary nav
            if (previousTop - currentTop > scrollDelta) {
                //if scrolling up...
                mainHeader.removeClass('is-hidden');
                mainHeader.addClass('is-shown'); //this has been added by Elhamy

                console.log('scrollingup')
                console.log('previousTop2', previousTop)
                console.log('currentTop2', currentTop)
                addStyle();
            } else if (currentTop - previousTop > scrollDelta && currentTop > scrollOffset) {
                //if scrolling down...
                mainHeader.addClass('is-hidden');
                mainHeader.removeClass('is-shown'); //this has been added by Elhamy

                // for hamburger menu
                //   $('.hamburger').removeClass('is-active');
                // for accordion menu
                $('.menu_accordion').removeClass('is-active');

                if (mainHeader.hasClass('nav-open')) { //this has been added by Elhamy
                    mainHeader.removeClass('nav-open'); //this has been added by Elhamy

                } //this has been added by Elhamy
                console.log('scrollingdown')
                console.log('previousTop3', previousTop)
                console.log('currentTop3', currentTop)
                addStyle();
            }
        }

        function addStyle() {
            var logo = $('.logo a img');
            var logoimg = $('.logo a img').attr('src');
            console.log('logoimg', logoimg, previousTop + currentTop)
            if ((previousTop + currentTop > 60)) {
                mainHeader.addClass('styled');


                logoimg = logo.attr('src', logo_colored);
                console.log('add the style')

            } else {
                mainHeader.removeClass('styled');
                logoimg = logo.attr('src', logo_white);
                console.log('remove the style')

            }
        }

        function checkStickyNavigation(currentTop) {
            //secondary nav below intro section - sticky secondary nav
            var secondaryNavOffsetTop = belowNavHeroContent.offset().top - secondaryNavigation.height() - mainHeader.height();

            if (previousTop >= currentTop) {
                //if scrolling up... 
                if (currentTop < secondaryNavOffsetTop) {
                    //secondary nav is not fixed
                    mainHeader.removeClass('is-hidden');
                    mainHeader.addClass('inscroll-top'); //this has been added by Elhamy
                    secondaryNavigation.removeClass('fixed slide-up');
                    belowNavHeroContent.removeClass('secondary-nav-fixed');
                } else if (previousTop - currentTop > scrollDelta) {
                    //secondary nav is fixed
                    mainHeader.removeClass('is-hidden');
                    mainHeader.addClass('inscroll-top'); //this has been added by Elhamy
                    secondaryNavigation.removeClass('slide-up').addClass('fixed');
                    belowNavHeroContent.addClass('secondary-nav-fixed');
                }

            } else {
                //if scrolling down...	
                if (currentTop > secondaryNavOffsetTop + scrollOffset) {
                    //hide primary nav
                    mainHeader.addClass('is-hidden');
                    mainHeader.removeClass('inscroll-top'); //this has been added by Elhamy
                    secondaryNavigation.addClass('fixed slide-up');
                    belowNavHeroContent.addClass('secondary-nav-fixed');
                } else if (currentTop > secondaryNavOffsetTop) {
                    //once the secondary nav is fixed, do not hide primary nav if you haven't scrolled more than scrollOffset 
                    mainHeader.removeClass('is-hidden');
                    mainHeader.addClass('inscroll-top'); //this has been added by Elhamy
                    secondaryNavigation.addClass('fixed').removeClass('slide-up');
                    belowNavHeroContent.addClass('secondary-nav-fixed');
                }

            }
        }
    })
    $('.cd-main-content, .footer').click(() => {
        if ($('body').hasClass('menu-is-active')) {
            $('.hamburger').removeClass('is-active')
            $('.menu_accordion').removeClass('is-active')
            $('body').removeClass('menu-is-active')
            $('.sideCanvas').css('z-index', '-1')
        }
    })
}