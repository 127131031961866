import Swiper from 'swiper';




function swiperSuccessStories() {
    let swiper_description = $('.swiper_description.swiper-container');
    const mySwiper_des = new Swiper($(swiper_description), {
        spaceBetween: 10,
        // init: false,
        pagination: {
            el: '.swiper_description .swiper-pagination',
            clickable: true,
        },
        loop: false,
        autoplay: false,
        effect: 'none',
        speed: 700,
    })
    let swiper_img = $('.swiper_img.swiper-container');
    const mySwiper_img = new Swiper($(swiper_img), {
        // spaceBetween: 10,
        // init: false,
        loop: false,
        effect: 'none',
        speed: 700,
        preloadImages: false,
        lazy: true
    })

    mySwiper_des.controller.control = mySwiper_img;
    mySwiper_img.controller.control = mySwiper_des;



}

if ($('.success_stories .swiper_description.swiper-container').length) {
    swiperSuccessStories();
}