const SnazzyInfoWindow = require('snazzy-info-window');
const dynamics = require('dynamics.js');
var map,
    infoWindow;
var temp_markers = [];
var pin_class = 'pin_logo_ico';
var filter_btn_click = 0;

function get_html_content(data) {
    if ($('.search_location.advanced_search_section').length) {
        var output = '<section class="custom-content">';
        output += '<h1 class="custom-header d-flex align-items-center">';


        output += '<span class="infobubble-property-title">' + data.title + '</span>';
        output += '</h1>';

        if ((data.desc != '') && (data.desc != 'undefined') && (data.desc != undefined) && (data.desc !== null) && (data.desc != null) && (typeof data.desc !== "undefined")) {


            output += '<div class=" infobubble-property-title">';
            // output += '<img src="' + themeURL + 'images/location.png" class="mr-2 ">';
            output += '<div class="small">' + data.desc + '</div>';
            output += '</div>';
        }

        if ((data.link_url != '') && (data.link_url != 'undefined') && (data.link_url != undefined) && (data.link_url !== null) && (data.link_url != null) && (typeof data.link_url !== "undefined")) {

            output += '<div class=" infobubble-property-title">';
            // output += '<img src="' + themeURL + 'images/location.png" class="mr-2 ">';
            output += '<a class="btn ebtn-4 mt-2 ebtn-sm p-1 small"  href="' + data.link_url + '">' + data.link_name + ' </a>'
            output += '</div>';

        }


        output += '<div class="custom-body property-infobubble-content">';
        output += '<div class="property-type-status"></div>';
        output += '<ul class="snazzy_ul">';



        output += '</ul>';
        output += '</div>';
        output += '</section>';

        return output;
    } else {
        var output = '<section class="custom-content">';
        output += '<h1 class="custom-header d-flex align-items-center">';

        output += '<span class="infobubble-property-title">' + data.name + '</span>';
        output += '</h1>';
        output += '<div class="custom-body property-infobubble-content">';
        output += '<div class="property-type-status"></div>';
        output += '<ul class="snazzy_ul">';
        if ((data.desc != '') && (data.desc != 'undefined') && (data.desc != undefined) && (data.desc !== null) && (data.desc != null) && (typeof data.desc !== "undefined")) {


            output += '<li class="d-flex list-inline mb-2 align-items-center">';
            // output += '<img src="' + themeURL + 'images/location.png" class="mr-2 ">';
            output += '<div>' + data.desc + '</div>';
            output += '</li>';
            console.log('desc', data.desc)
        }
        if ((data.telephone != '') && (data.telephone != 'undefined') && (data.telephone != undefined) && (data.telephone !== null) && (data.telephone != null) && (typeof data.desc !== "undefined")) {

            output += '<li class="d-flex list-inline mb-2 align-items-center"> <span>Tel: </span>';
            // output += '<img src="' + themeURL + 'images/phone.png" class="mr-2 ">';
            output += '<a href="tel:' + data.telephone + '">';
            output += '<div> ' + data.telephone + '</div>';
            output += '</a>';
            output += '</li>';
        }


        if ((data.email != '') && (data.email != 'undefined') && (data.email != undefined) && (data.email !== null) && (data.email != null) && (typeof data.email !== "undefined")) {
            output += '<li class="d-flex list-inline mb-2 align-items-center"> <span>Email: </span>';
            output += '<a href="mailto:"' + data.email + 'target="_top">';
            output += '<div>' + data.email + '</div>';
            output += '</a> ';
            output += '</li>';
        }
        output += '</ul>';
        output += '</div>';
        output += '</section>';


        return output;
    }


};

function get_html_content_nearest(data) {
    var output = '<section class="custom-content">';
    output += '<h1 class="custom-header justify-content-between d-flex align-items-center">';
    output += '<span class="infobubble-property-title"> Your nearest store is ' + data.name + '</span>';
    output += '</h1>';
    output += '<div class="custom-body property-infobubble-content">';
    output += '<div class="property-type-status"></div>';
    output += '<ul class="snazzy_ul">';
    output += '<li class="d-flex list-inline mb-2 align-items-center">';
    output += '<div>' + data.desc + '</div>';
    output += '</li>';

    output += '</ul>';
    output += '</div>';
    output += '</section>';

    return output;

}

if ($('#gmap').length) {
    initMap()
}



function initMap() {
    // var $ = require('jquery');




    CustomMarker.prototype = new google.maps.OverlayView();

    function CustomMarker(opts) {
        this.setValues(opts);
    }






    function get_project_marker_logo(store_id) {
        var temp_store = temp_items.find(function(x) {
            return x['id'] == store_id;
        });
        if (temp_store) {
            pin_class = 'pin_project_logo'
                // return 'Upload-RealEstate-Project-Filename2-' + temp_store['filename2'];
            return temp_store['mainImage'];

        }
        pin_class = 'pin_logo_ico'
        return themeURL + '/images/logo.png';
    };


    function get_marker_logo(market_id) {
        var temp_market = temp_markets.find(function(x) {
            return x['id'] == market_id;
        });
        // if (temp_market) {
        //     return 'Upload_StoreLocator_Entity_Db_Market_Filename_' + temp_market['logo'];
        // }
        return themeURL + '/images/logo.png';
    };

    CustomMarker.prototype.draw = function() {
        var self = this;
        var div = this.div;
        // var pinLogo = $('storeblock').each().find('.store_img').attr('src');
        if ($('.search_location.advanced_search_section').length) {
            var pinLogo = get_project_marker_logo(this.more_data.id);
        } else {
            var pinLogo = get_marker_logo(this.more_data.id);

        }


        if (!div) {
            div = this.div = $('' +
                '<div class="marker_wrapp">' +
                '<div class="shadow"></div>' +
                '<div class="pulse"></div>' +
                '<div class="pin-wrap">' +
                '<div class="pin">' +
                '</div>' +
                '<div class="pin_logo">' +
                // '<img class="img-fluid ' + pin_class + '" src=require("' + pinLogo + '")>' +
                '<img class="img-fluid ' + pin_class + '" src="' + pinLogo + '">' +
                '</div>' +
                '</div>' +
                '</div>' +
                '')[0];
            this.pinWrap = this.div.getElementsByClassName('pin-wrap');
            this.pin = this.div.getElementsByClassName('pin');
            this.pinShadow = this.div.getElementsByClassName('shadow');
            this.pin_logo = this.div.getElementsByClassName('pin_logo');
            div.style.position = 'absolute';
            div.style.cursor = 'pointer';
            var panes = this.getPanes();
            panes.overlayImage.appendChild(div);
            google.maps.event.addDomListener(div, "click", function(event) {
                google.maps.event.trigger(self, "click", event);
            });
        }
        var point = this.getProjection().fromLatLngToDivPixel(this.position);
        if (point) {
            div.style.left = point.x + 'px';
            div.style.top = point.y + 'px';
        }
    };
    CustomMarker.prototype.animateDrop = function() {
        dynamics.stop(this.pinWrap);
        dynamics.css(this.pinWrap, {
            'transform': 'scaleY(2) translateY(-' + $('#gmap').outerHeight() + 'px)',
            'opacity': '1',
        });
        dynamics.animate(this.pinWrap, {
            translateY: 0,
            scaleY: 1.0,
        }, {
            type: dynamics.gravity,
            duration: 1800,
        });

        dynamics.stop(this.pin);
        dynamics.css(this.pin, {
            'transform': 'none',
        });
        dynamics.animate(this.pin, {
            scaleY: 0.8
        }, {
            type: dynamics.bounce,
            duration: 1800,
            bounciness: 600,
        })

        dynamics.stop(this.pinShadow);
        dynamics.css(this.pinShadow, {
            'transform': 'scale(0,0)',
        });
        dynamics.animate(this.pinShadow, {
            scale: 1,
        }, {
            type: dynamics.gravity,
            duration: 1800,
        });
    }

    CustomMarker.prototype.animateBounce = function() {
        dynamics.stop(this.pinWrap);
        dynamics.css(this.pinWrap, {
            'transform': 'none',
        });
        dynamics.animate(this.pinWrap, {
            translateY: -30
        }, {
            type: dynamics.forceWithGravity,
            bounciness: 0,
            duration: 500,
            delay: 150,
        });

        dynamics.stop(this.pin);
        dynamics.css(this.pin, {
            'transform': 'none',
        });
        dynamics.animate(this.pinWrap, {
            scaleY: 0.8
        }, {
            type: dynamics.bounce,
            duration: 800,
            bounciness: 0,
        });
        dynamics.animate(this.pinWrap, {
            scaleY: 0.8
        }, {
            type: dynamics.bounce,
            duration: 800,
            bounciness: 600,
            delay: 650,
        });

        dynamics.stop(this.pinShadow);
        dynamics.css(this.pinShadow, {
            'transform': 'none',
        });
        dynamics.animate(this.pinShadow, {
            scale: 0.6,
        }, {
            type: dynamics.forceWithGravity,
            bounciness: 0,
            duration: 500,
            delay: 150,
        });
    }

    CustomMarker.prototype.animateWobble = function() {
        dynamics.stop(this.pinWrap);
        dynamics.css(this.pinWrap, {
            'transform': 'none',
        });
        dynamics.animate(this.pinWrap, {
            rotateZ: -45,
        }, {
            type: dynamics.bounce,
            duration: 1800,
        });

        dynamics.stop(this.pin);
        dynamics.css(this.pin, {
            'transform': 'none',
        });
        dynamics.animate(this.pin, {
            scaleX: 0.8
        }, {
            type: dynamics.bounce,
            duration: 800,
            bounciness: 1800,
        });
    }
    CustomMarker.prototype.getMap = function() {
        return this.map;
    }

    // var $ = require('jquery');
    $(function() {

        var pos = new google.maps.LatLng(default_latitude, default_longitude);
        map = new google.maps.Map(document.getElementById('gmap'), {
            zoom: default_zoom,
            center: pos,
            disableDefaultUI: true,
            zoomControl: false,
            scaleControl: false,
            styles: [{
                    "featureType": "administrative",
                    "elementType": "geometry.fill",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "administrative",
                    "elementType": "labels.text",
                    "stylers": [{
                            "visibility": "on"
                        },
                        {
                            "color": "#8e8e8e"
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#7f7f7f"
                    }]
                },
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.stroke",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "administrative.country",
                    "elementType": "geometry.stroke",
                    "stylers": [{
                        "color": "#bebebe"
                    }]
                },
                {
                    "featureType": "administrative.province",
                    "elementType": "geometry.stroke",
                    "stylers": [{
                            "visibility": "on"
                        },
                        {
                            "color": "#cbcbcb"
                        },
                        {
                            "weight": "0.69"
                        }
                    ]
                },
                {
                    "featureType": "administrative.locality",
                    "elementType": "geometry",
                    "stylers": [{
                        "visibility": "simplified"
                    }]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [{
                        "color": "#e4e4e4"
                    }]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [{
                            "saturation": -100
                        },
                        {
                            "lightness": 45
                        },
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry.stroke",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "road",
                    "elementType": "labels",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [{
                            "visibility": "simplified"
                        },
                        {
                            "color": "#dadada"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text",
                    "stylers": [{
                        "visibility": "simplified"
                    }]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "all",
                    "stylers": [{
                        "visibility": "on"
                    }]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.text",
                    "stylers": [{
                        "visibility": "simplified"
                    }]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.icon",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "road.local",
                    "elementType": "all",
                    "stylers": [{
                        "visibility": "simplified"
                    }]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#eeeeee"
                    }]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text",
                    "stylers": [{
                        "visibility": "simplified"
                    }]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [{
                            "color": "#cbcbcb"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry.fill",
                    "stylers": [{
                        "color": "#d9d9d9"
                    }]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry.stroke",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text",
                    "stylers": [{
                        "visibility": "simplified"
                    }]
                }
            ]

        });
        if ($('.main-cats-to-filter').length) {
            temp_items = temp_items_1
            setTimeout(function() {
                $('.main-cats-to-filter .btn.active').click();
                console.log('.main-cats-to-filter .btn.active click');


            }, 400)
        }

        for (var i in temp_items) {
            var temp_item = temp_items[i];
            var temp_item_position = new google.maps.LatLng(temp_item['latitude'], temp_item['longitude']);
            var temp_marker = new CustomMarker({
                position: temp_item_position,
                map: map,
                store_id: temp_item['id'],
                more_data: temp_item
            });

            infoWindow = new SnazzyInfoWindow({
                marker: temp_marker,
                content: get_html_content(temp_item),
                wrapperClass: 'custom-window row',
                position: temp_item_position,
                offset: {
                    top: '0px',
                    left: '-200px'
                },
                edgeOffset: {
                    top: 50,
                    right: 30,
                    left: 30,
                    bottom: 30
                },
                showCloseButton: true,
                border: false,
                closeButtonMarkup: '<button type="button" class="btn custom-close">&#215;</button>',
                callbacks: {
                    beforeOpen: function() {},
                    open: function() {},
                    afterOpen: function() {
                        var that = this;
                        var wrapper = $(this.getWrapper());
                        wrapper.addClass('active');
                        wrapper.find('.custom-close').on('click', function() {
                            that.close();
                        });
                    },
                    beforeClose: function() {},
                    close: function() {
                        this.close();
                    },
                    afterClose: function() {
                        var wrapper = $(this.getWrapper());
                        wrapper.find('.custom-close').off();
                        wrapper.removeClass('open');
                    }
                }
            });

            google.maps.event.addListener(temp_marker, 'click', function(e) {
                this.animateBounce();
                map.setZoom(default_zoom + 3);
                map.panTo(this.position);

            });

            temp_markers.push(temp_marker);
        }


        $('.filter-btn').click(function(e) {
            filter_btn_click++;
            console.log('.filter-btn click')
            var item_id = $(this).data('id');
            $('.filter-btn').removeClass('active');
            $(this).addClass('active');
            gotostores(item_id);
            var body = $("html, body");
            if (filter_btn_click > 1)
                body.stop().animate({ scrollTop: $(".home-map-filter .gmap").offset().top }, 500, 'swing', function() {});
        })


        $('.main-cats-to-filter .btn').click(function(e) {

            var array_name = $(this).data('array');
            $('.main-cats-to-filter .btn').removeClass('active');
            $(this).addClass('active');
            //alert(array_name)
            clearMarkers();
            // filters-buttons
            temp_items = window[array_name];
            temp_markers = []
            console.log('your temp_items is', temp_items)
                // gotospeceficstoresarray(window[array_name]);
            for (var i in temp_items) {
                var temp_item = temp_items[i];
                var temp_item_position = new google.maps.LatLng(temp_item['latitude'], temp_item['longitude']);
                var temp_marker = new CustomMarker({
                    position: temp_item_position,
                    map: map,
                    store_id: temp_item['id'],
                    more_data: temp_item
                });

                infoWindow = new SnazzyInfoWindow({
                    marker: temp_marker,
                    content: get_html_content(temp_item),
                    wrapperClass: 'custom-window row',
                    position: temp_item_position,
                    offset: {
                        top: '0px',
                        left: '-200px'
                    },
                    edgeOffset: {
                        top: 50,
                        right: 30,
                        left: 30,
                        bottom: 30
                    },
                    showCloseButton: true,
                    border: false,
                    closeButtonMarkup: '<button type="button" class="btn custom-close">&#215;</button>',
                    callbacks: {
                        beforeOpen: function() {},
                        open: function() {},
                        afterOpen: function() {
                            var that = this;
                            var wrapper = $(this.getWrapper());
                            wrapper.addClass('active');
                            wrapper.find('.custom-close').on('click', function() {
                                that.close();
                            });
                        },
                        beforeClose: function() {},
                        close: function() {
                            this.close();
                        },
                        afterClose: function() {
                            var wrapper = $(this.getWrapper());
                            wrapper.find('.custom-close').off();
                            wrapper.removeClass('open');
                        }
                    }
                });

                google.maps.event.addListener(temp_marker, 'click', function(e) {
                    this.animateBounce();
                    map.setZoom(default_zoom + 3);
                    map.panTo(this.position);

                });

                temp_markers.push(temp_marker);
            }
            setTimeout(function() {
                $('.filter-btn.active').click();
            }, 100);



            console.log('filter_btn_click', filter_btn_click)


        })







        function altFind(arr, callback) {
            var arrs = [];
            for (var i = 0; i < arr.length; i++) {
                var match = callback(arr[i]);

                if (match) {
                    arrs.push(arr[i]);

                }
            }
            return arrs;
        }

        function gotostore() {
            var item_id = $(this).val();
            gotostoreval(item_id);
        }

        function gotostores(item_id) {
            gotospeceficstores(item_id)

        }

        function gotostoreval(val) {
            var item_id = val;
            var temp_marker = [];
            temp_marker = altFind(temp_markers, function(element) {
                return element.store_id == item_id;
            });
            if (temp_marker[0]) {
                temp_marker[0].animateBounce();
                map.setZoom(default_zoom + 3);
                map.panTo(temp_marker[0].position);
            }




        }






        function gotospeceficstores(val) {
            clearMarkers();

            var item_id = val;
            var temp_marker = [];
            if (val == 0) {
                temp_marker = temp_markers
            } else {
                temp_marker = altFind(temp_markers, function(element) {
                    return element.more_data.yearFilter == item_id;
                });
            }
            console.log('temp_markers', temp_markers)
            var bounds = new google.maps.LatLngBounds();

            for (var i = 0; i < temp_marker.length; i++) {
                if (temp_marker[i]) {

                    $(temp_marker[i].div).css('display', 'block');


                    var positions = new google.maps.LatLng(temp_marker[i].more_data.latitude, temp_marker[i].more_data.longitude);
                    temp_marker[i].animateBounce();
                    var loc = positions;
                    bounds.extend(loc);
                }
            }

            map.fitBounds(bounds);
            map.panToBounds(bounds);



        }




        function gotospeceficstoresarray(arr) {
            clearMarkers();
            console.log('array is', arr)
            var item_id = 0;
            var temp_marker = [];
            temp_marker = arr;

            var bounds = new google.maps.LatLngBounds();

            for (var i = 0; i < temp_marker.length; i++) {
                if (temp_marker[i]) {

                    $(temp_marker[i].div).css('display', 'block');


                    var positions = new google.maps.LatLng(temp_marker[i].more_data.latitude, temp_marker[i].more_data.longitude);
                    temp_marker[i].animateBounce();
                    var loc = positions;
                    bounds.extend(loc);
                }
            }

            map.fitBounds(bounds);
            map.panToBounds(bounds);



        }




        function setMapOnAll() {
            $('.marker_wrapp').css('display', 'none');
            $('.marker_wrapp').removeClass('in-view').addClass('marker-in-view');
        }

        function clearMarkers() {
            infoWindow.close();
            $(".custom-close").click();
            setMapOnAll();

        }








    });
};
// initMap()







// var $ = require('jquery');
$(".custom-close").click(function() {
    alert('closw me now')

    infoWindow.close();
});



function rad(x) { return x * Math.PI / 180; }

function getNearestFromMarkers(pos) {
    var temp = false;
    var nearestMarker = false;

    for (var i = 0; i < temp_items.length; i++) {


        var B = new google.maps.LatLng(temp_items[i].latitude, temp_items[i].longitude);
        var distance = google.maps.geometry.spherical.computeDistanceBetween(pos, B);


        if (temp === false || temp > distance) {
            temp = distance;
            nearestMarker = temp_items[i];
        }
    }
    //alert('we' + nearestMarker);

    return nearestMarker;
}

function find_closest_marker(pos) {

    var lat = pos.lat;
    var lng = pos.lat;
    var R = 6371; // radius of earth in km
    var distances = [];
    var closest = -1;
    for (i = 0; i < temp_items.length; i++) {

        var mlat = parseFloat(temp_items[i].latitude);
        var mlng = parseFloat(temp_items[i].longitude);
        var dLat = rad(mlat - lat);
        var dLong = rad(mlng - lng);
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(rad(lat)) * Math.cos(rad(lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        distances[i] = d;
        if (closest == -1 || d < distances[closest]) {
            closest = i;
        }
    }

    return (temp_items[closest]);
}