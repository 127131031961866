var jQueryBridget = require('jquery-bridget')
var InfiniteScroll = require('infinite-scroll')
jQueryBridget('infiniteScroll', InfiniteScroll, $)

if ($('.loadMoreContainer').length) {
    $('.loadMoreContainer').infiniteScroll({
        // options
        path: function() {
            if (no_more) { return false }

            return page_new_url
        },
        history: false,
        responseType: 'text',
        status: '.page-load-status',
        button: '.loadmoreitems',
        scrollThreshold: false,
        debug: true,
    })

    $('.loadMoreContainer').on('load.infiniteScroll', function(event, response) {
        var $items = $(response).find('.loadMoreContainer .p-item')

        if ($items.length < 9) {
            no_more = true
            $('.loadmoreitems').addClass('d-none')
        }

        $('.loadMoreContainer').append($items)

    })
}