// Styles
// import "../node_modules/bootstrap/scss/bootstrap.scss";
import "./assets/styles/main-style-ltr.scss";

//Scriptsbootstrap-slider.scss
import 'bootstrap';
import 'animate.css'

$(document).ready(() => {

    require('./assets/scripts/persons_missing');
    require('./assets/scripts/success_stories');
    require('./assets/scripts/auto-hide-header');
    require('./assets/scripts/forms');
    require('./assets/scripts/custom');

    require('./assets/scripts/mainbanner-swiper');
    require('./assets/scripts/google_map_filters');
    require('./assets/scripts/filter');
    require('./assets/scripts/modal_swiper');
    require('./assets/scripts/news_swiper');
    require('./assets/scripts/infinite_scroll');
    require('./assets/scripts/vimeo-fullscreen');

});