import Swiper from 'swiper';



function swiperPersonsMissing() {
    $('.persons_missing .swiper-container').each(function() {
        let mySwiper = new Swiper($(this), {
            slidesPerView: 5,
            slidesPerColumn: 2,
            spaceBetween: 10,
            // init: false,
            pagination: {
                el: $(this).parent().parent().find('.swiper-pagination'),
                clickable: true,
            },
            breakpoints: {
                1800: {
                    slidesPerView: 4,
                },
                1024: {
                    slidesPerView: 3,
                },
                800: {
                    slidesPerView: 2,
                },
                350: {
                    slidesPerView: 1,
                }
            }
            // loop: true,
            // effect: 'fade',
            // speed: 700,

        })
    })
}

if ($('.persons_missing').length) {
    swiperPersonsMissing();
}
