import Swiper from 'swiper';



function swiperslider() {
    $('.swiper_slider.swiper-container').each(function() {
        let mySwiper = new Swiper($(this), {
            slidesPerView: 1,
            spaceBetween: 10,
            loop: true,
            autoplay: {
                delay: 2500,
            },
            speed: 500,
            effect: 'fade',
            // init: false,
            pagination: {
                el: $(this).find('.swiper-pagination'),
                clickable: true,
            },

            // loop: true,
            // effect: 'fade',
            // speed: 700,

        })
    })
}

if ($('.swiper_slider.swiper-container').length) {
    swiperslider();
}