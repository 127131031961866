// $('.mouse').click(function(){
//     $('html, body').animate({
//         scrollTop: ($('.home-video-wrapp').outerHeight())
//     },500);
// })


$('.open_vedio_emo').click(function() {
    var vedio_emo = $(this).attr('vedio_emo');
    $("body").addClass('modal-show')
    $(".vedio_ifame_m").addClass('md-show');
    $(".vedio_ifame_m iframe").attr('src', vedio_emo);

})

$("body").delegate(".iframe", "click", function() {
    if (!$(e.target).hasClass('player')) {
        $(".vedio_ifame_m").removeClass('md-show');
        $('.vedio_ifame_m iframe').attr('src', '');
    }
});

$('.close_vedio_emo').click(function() {
    $("body").removeClass('modal-show')

    $(".vedio_ifame_m").removeClass('md-show');
    $('.vedio_ifame_m iframe').attr('src', '');
})