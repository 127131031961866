var bootstrapSelect = require('bootstrap-select');
var bootstrapdatepicker = require('bootstrap-datepicker');


if ($('.selectpicker').length) {
    $('.selectpicker').selectpicker();

}


if ($('.datepicker').length) {
    $('.datepicker').datepicker({ "autoclose": true, "format": "yyyy-m-d" });
}