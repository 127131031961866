import Swiper from 'swiper'
// import SwiperAnimation from 'swiper-animation';

function mainBannerSwiper() {
    // let swiperAnimation = new SwiperAnimation();
    let swiper1 = $('.mainbanner-swiper .swiper-container');
    let mySwiper = new Swiper($(swiper1), {
        // spaceBetween: 10,
        // init: false,
        pagination: {
            el: '.mainbanner-swiper .swiper-pagination',
            clickable: true,
        },
        loop: false,
        autoplay: false,
        effect: 'none',
        speed: 700,
    })
    let swiper2 = $('.mainbanner-swiper-image .swiper-container');
    let mySwiper1 = new Swiper($(swiper2), {
        // spaceBetween: 10,
        // init: false,
        loop: false,
        effect: 'none',
        // autoplay: true,
        speed: 700,
        preloadImages: false,
        // Enable lazy loading
        lazy: true,
    })

    // mySwiper.on('slideChange', function () {
    // 	let activeSlide = mySwiper.activeIndex;
    // 	let currentActive = mySwiper.slides[activeSlide];
    // 	let el = mySwiper.el
    // 	$(currentActive).addClass('animated_FS')

    // 	console.log({
    // 		activeSlide,
    // 		currentActive,
    // 		el
    // 	})
    // 	console.error('active Slide', currentActive)
    // });


    mySwiper.controller.control = mySwiper1;
    mySwiper1.controller.control = mySwiper;


    // $('.mainbanner-swiper-main-image .swiper-container').each(function() {
    let swiperImage1 = $('.mainbanner-swiper-main-image .swiper-container');
    let mySwiper2 = new Swiper($(swiperImage1), {
        slidesPerView: 1,
        slidesPerColumn: 1,
        // spaceBetween: 10,
        // init: false,
        loop: true,
        autoplay: {
            delay: 8000,
        },
        effect: 'fade',
        speed: 700,
        preloadImages: false,
        pagination: {
            el: '.mainbanner-swiper-main-image .swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: $(this).parent().find('.swiper-button-next'),
            prevEl: $(this).parent().find('.swiper-button-prev'),
        },
        // Enable lazy loading
        lazy: true,

        // on: {
        // 	init: () => swiperAnimation.init(mySwiper2).animate(),
        // 	slideChange: () => swiperAnimation.init(mySwiper2).animate()
        // }

    })
    let swiperImage2 = $('.mainbanner-swiper-main-text.swiper-container');
    let mySwiper4 = new Swiper($(swiperImage2), {
        // spaceBetween: 10,
        // init: false,

        loop: true,
        autoplay: false,
        effect: 'none',
        speed: 700,
    })
    mySwiper2.controller.control = mySwiper4;
    mySwiper4.controller.control = mySwiper2;

    swiperImage2.hover(function() {
        mySwiper2.autoplay.stop();
    }, function() {

        mySwiper2.autoplay.start();
    })

    swiperImage1.hover(function() {
        mySwiper2.autoplay.stop();
    }, function() {

        mySwiper2.autoplay.start();
    })


    // })
}

// $('.mainbanner-swiper-main-text.swiper-container').each(function() {
// let swiper1 = $('.mainbanner-swiper-main-text.swiper-container');
// let mySwiper = new Swiper($(swiper1), {
//     // spaceBetween: 10,
//     // init: false,

//     loop: true,
//     autoplay: false,
//     effect: 'none',
//     speed: 700,
// })
// })



if ($('.main-banner').length)
    mainBannerSwiper()