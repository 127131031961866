import Swiper from 'swiper';


if ($('.sucess_stories_inner').length) {
    $('.modal_persons_missing.modal .modal-dialog').removeClass('').addClass('container_1');
}


function init_swiper() {
    let swiper_top = $('.modal_persons_missing .galler_persons .gallery-top');
    let galleryTop = new Swiper($(swiper_top), {
        spaceBetween: 10,
        // effect: "fade",
        pagination: {
            el: '.modal_persons_missing .galler_persons .swiper-pagination',
            clickable: true,
        },
    })
    let gallery_thumbs = $('.modal_persons_missing .galler_persons .gallery-thumbs');
    let galleryThumbs = new Swiper($(gallery_thumbs), {
        spaceBetween: 10,
        slidesPerView: 4,
        slideToClickedSlide: true,
    })

    galleryTop.on('slideChangeTransitionStart', function() {

        var galleryTop_index = $('.gallery-top.swiper-container').find('.swiper-slide-active').index();
        $('.gallery-thumbs .swiper-slide').removeClass('active');
        $('.gallery-thumbs .swiper-slide').eq(galleryTop_index).addClass('active');

        galleryThumbs.slideTo(galleryTop_index, 500);

    });

    $('.gallery-thumbs .swiper-slide').click(function() {

        $('.gallery-thumbs .swiper-slide').removeClass('active');

        $(this).addClass('active');

        var slide = parseInt($(this).index());

        galleryTop.slideTo(slide, 500);

    });

}
$('#modal_persons_missing').on('show.bs.modal', function(event) {
    $('.modal_loader').removeClass('d-none');
    var that = this;
    var target = $(event.relatedTarget);
    var jqxhr = $.ajax(target.data('url'))
        .done(function(html) {
            $(that).find('.modal-body').html(html);
            init_swiper();
        })
        .fail(function() {
            alert("error");
        })
        .always(function() {
            // alert( "complete" );
        });
    jqxhr.always(function() {
        $('.modal_loader').addClass('d-none');
    });
});

if ($('.sec_modal_details').length) {
    init_swiper();
}