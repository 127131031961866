// require("jquery-mousewheel");
// require('malihu-custom-scrollbar-plugin');




if ($('.accordion').length) {


    // $('#accordion').on('shown.bs.collapse', function() {
    //     var panel = $(this).find('.show');

    //     $('html, body').animate({
    //         scrollTop: panel.offset().top - 100
    //     }, 500);

    // });




    var $accordion = $('.accordion')

    $accordion.each(function (i) {
        $accordion.eq(i).on('shown.bs.collapse', function () {
            var panel = $(this).find('.show');

            $('html, body').animate({
                scrollTop: panel.offset().top - 100
            }, 500)

        })


    })



    $('.accordion .card-header').click(function () {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active')

        } else {
            $(this).closest(".accordion").find('.card-header').removeClass('active')
            $(this).addClass('active')
        }

    })

}

if ($('.play-video').length) {
    $('.play-video').click(function (e) {
        var videosrc = $(this).attr('videosrc')
        var videtitle = $(this).data('title')
        $('#videoModal').modal('show')
        $('#videoModal iframe').attr('src', videosrc)
        $('#videoModal .details').html(videtitle)

    })

    $('#videoModal').on('hidden.bs.modal', function (e) {
        $('#videoModal iframe').attr('src', '')
    })
}

// Menu

// $('.link_btn_collapse').click(function(){
// 	$('.link_btn_collapse').not(".collapsed").parent().addClass('active_menu_item');
// 	$('.link_btn_collapse.collapsed').parent().removeClass('active_menu_item');
// });

// Scroll
//$(".scroll-bar").mCustomScrollbar();

$(document).ready(function () {
    setTimeout(function () {
        $('.loading_overlay').fadeOut();
    }, 200);
    if (($('.welcome_Modal').length) > 0) {
        setTimeout(function () {
            $('.welcome_Modal').modal('show')
        }, 1500);
    }

});